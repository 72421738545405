const TOP_NAV_CLASS = ".top-bar";
const SMOOTHSCROLL_CLASS = '.smooth';
const BURGER_ID ='#burger';
let topNavNode = '';
var menuOpen = false;

export default class Navigation {

    constructor () {
        this.topNavNode = this.getTopNavNode();
        this.init();
    }

    init() {
        let items = Array.from(document.querySelectorAll(SMOOTHSCROLL_CLASS));
        items.forEach(element => {
            element.addEventListener('click', this.scrollTo);
        });
        document.querySelector(BURGER_ID).addEventListener('click', this.openMenu)
    }

    getTopNavNode () {
        return document.querySelector(TOP_NAV_CLASS);
    }

    scrollTo(){
        event.preventDefault();
        
        document.querySelector(this.getAttribute('href').substring(1)).scrollIntoView({ 
             behavior: 'smooth' 
        });
    }

    openMenu(){
        let bars = this.children;
        let items = Array.from(bars);
        items.forEach(element => {
            element.classList.toggle('change');
        });
        let navEl = document.querySelector('nav .menu');
        if(menuOpen) {
            navEl.style.display = 'none';
            menuOpen = false;
        }else{
            navEl.style.display = 'inline-block';
            menuOpen = true;
        }

    }

    adjTopBar(action) {
        switch(action){
            case 'shrink': this.topNavNode.style.padding = '1rem 6rem'; break;
            case 'default': this.topNavNode.style.padding = '2rem 6rem'; break;
        }    
    }

}