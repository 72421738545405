// import Swiper JS
import Swiper, { Pagination, Autoplay } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

Swiper.use([Pagination, Autoplay]);


const swiper = new Swiper('.swiper', {
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
});