import './components/swiper';
import Navigation from './components/navigation';
import Accordion from './components/accordion';

let navObj = new Navigation();
let navAdj = false;

let accordionObj = new Accordion();

window.addEventListener('scroll', (event) => {

    if(document.body.clientWidth > 1024) {
        if(scrollY > 150 && !navAdj) {
            navObj.adjTopBar('shrink');
            navAdj = true;
        }
        if(scrollY < 150 && navAdj) {
            navObj.adjTopBar('default');
            navAdj = false;
        }
    }
});

