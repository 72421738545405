const SELECTOR_ITEM = 'accordion__item';
const CLASS_ACTIVE = 'accordion__item--active';
const CLASS_PANEL =  'accordion__item__panel';
const doc = document;

export default class Accordion  {
  constructor() {

    this.onClick = this.onClick.bind(this);
    this.render();
  }

  render() {
 
    const items = Array.from(document.getElementsByClassName(SELECTOR_ITEM));
    const sections = items.map((item) => {
      const selector = item.getAttribute('id');
      const section = doc.querySelector(`#${selector}`);
     
      if (!section) {
        return null;
      }

      item.addEventListener('click', this.onClick);

      return section;
    }).filter((section) => section !== null);

    this.items = items;
    this.sections = sections;
  }

  

  onClick(event) {
    let clickedTab;
  
    if(event.target.nodeName == 'LABEL'){
      clickedTab = event.target.parentNode;
    }else{
      clickedTab = event.target;
    }

    const section = doc.querySelector(`#${clickedTab.getAttribute('id')}`);
    if (!section) {
      return;
    }
    clickedTab.classList.toggle(CLASS_ACTIVE);
   
    event.preventDefault();
  }

}